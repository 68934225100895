import React from "react"
import styled from "styled-components"

import { Type, Color, Wrapper } from "../../utilities"

const SectionContainer = styled.section`
  background: ${props => (props.background ? props.background : Color.white)};
  font-family: ${Type.text};
  color: ${Color.text};
  padding: 3.5rem 0;
`

export const Section = ({ children, wrapperMaxWidth, ...props }) => (
  <SectionContainer {...props}>
    <Wrapper maxWidth={wrapperMaxWidth}>{children}</Wrapper>
  </SectionContainer>
)
