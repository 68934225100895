import React from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"

import { Nav } from "../Nav"
import {
  Type,
  Wrapper,
  Media,
  Color,
  Grid,
  AlignRight,
  Iframe,
  Col,
} from "../../utilities"

const HeaderContainer = styled.div`
  padding: 1rem 0rem;
  font-family: ${Type.text};
  background: ${Color.white};
`

const Logo = styled.div`
  position: relative;
  display: inline-block;

  .leaf,
  .text {
    transition: all 0.5s;
  }

  a:hover {
    .leaf {
      transform: scale(1.02) rotate(-20deg);
    }
    .text {
      transform: scale(1.02);
    }
  }
`

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 400px;

  img {
    max-width: 100%;
  }

  ${Media.below.tablet`
    width: 250px;

    .leaf {
      width: 60px; 
    }
  `}
`

const src =
  "https://sylvaniaareafcu-dc.cert.fec-dc.fiservapps.com/idp/2A7CDDB3/tethered"

export const Header = ({ nav = [] }) => {
  const { logoText, leaf } = useStaticQuery(graphql`
    query HeaderQuery {
      leaf: file(relativePath: { eq: "leaf.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            src
          }
        }
      }
      logoText: file(relativePath: { eq: "logo-text.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <HeaderContainer>
        <Wrapper>
          <Grid>
            <Logo>
              <Link to="/">
                <LogoGrid>
                  <img src={leaf.childImageSharp.fluid.src} className="leaf" />
                  <img
                    src={logoText.childImageSharp.fluid.src}
                    className="text"
                  />
                </LogoGrid>
              </Link>
            </Logo>
            <AlignRight>
              <Iframe source={src} />
            </AlignRight>
          </Grid>
        </Wrapper>
      </HeaderContainer>
      <Nav nav={nav} />
    </>
  )
}
