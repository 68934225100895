import React from "react"
import styled from "styled-components"

import { Wrapper, Media, Color } from "../../utilities"

const BannerContainer = styled.section`
  position: relative;
  width: 100%;
  height: 350px;
  text-transform: uppercase;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  .react-parallax-background-children {
    width: 100%;
  }

  .react-parallax-content {
    height: 100%;
  }

  ${Media.below.tablet`
    text-align: center;
    height: 200px;

    ${props =>
      props.background &&
      `
      background-image: url(${props.background});
    `}
  `}
`

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;

  ${props =>
    props.image &&
    `
    background-position: center center;
      background-image: url('${props.image}');
  `}

  ${Media.below.tablet`
    display: none;
  `}
`

const BannerContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem !important;
    margin-bottom: 2rem !important;
    color: ${Color.white};
  }

  span {
    font-weight: bold;
    font-size: 6rem;
    color: ${Color.green};

    ${Media.below.tablet`
      line-height: 100%;
    `}
  }

  ${Media.below.tablet`
  text-align: center;
    p {
      font-size: 1rem !important;
      margin: 0 !important; 
    }
    span {
      font-size: 2.5rem;
    }
  `}
`

export const Banner = ({ background, children }) => (
  <BannerContainer strength={300} background={background}>
    <BackgroundImage image={background} />
    <BannerContent>
      <Wrapper>{children}</Wrapper>
    </BannerContent>
  </BannerContainer>
)
