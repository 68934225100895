export const Color = {
  white: "#fff",
  black: "#000",
  dark_green: "#245b3f",
  green: "#2BA355",
  lite_green: "#55B577",
  grey: "#777777",
  footer: "#29282E",
  bottom_footer: "#212025",
  footer_text: "#ACACAC",
}
