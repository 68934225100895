import React, { useState, Fragment } from "react"
import { darken, lighten } from "polished"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import { Color, Type, Media, Wrapper } from "../../utilities"

const NavContainer = styled.div`
  font-family: ${Type.text};
  background-size: cover;
  background-color: ${Color.green};
  background-position: center;
  grid-template-columns: auto 1fr auto;
  text-transform: uppercase;

  ${Media.below.tablet`
    margin-top: 0;
    display: block;
  `}
`

const NavFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${Media.below.tablet`
    margin: 0;
    display: ${props => (props.on ? `flex` : `none`)};
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
  `}
`

const ExitButton = styled.span`
  position: absolute;
  z-index: 9001;
  right: 0px;
  top: 0px;
  font-size: 2rem;
  height: 40px;
  width: 40px;
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 5px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  color: ${darken(0.4, Color.black)};
  border-radius: 100%;

  ${Media.below.tablet`
    display: flex;
  `}
`

const Burg = styled.div`
  display: none;
  z-index: 101;
  position: absolute;
  width: 25px;
  height: 4px;
  right: 20px;
  top: 40px;
  cursor: pointer;
  background: ${Color.green};

  &::before,
  &::after {
    left: 0;
    content: "";
    margin-top: -10px;
    position: absolute;
    width: 100%;
    height: 4px;
    background: ${Color.green};
  }

  &::after {
    margin-top: 10px;
    background: ${Color.green};
  }

  ${Media.below.tablet`
    display: block;
  `}
`

const linkCss = css`
  text-align: center;
  text-decoration: none;
  font-family: ${Type.text};
  color: ${Color.white};
  padding: 1.25rem 2rem;
  font-weight: bold;
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  position: relative;
  background-color: ${Color.green};
  font-size: 14px;

  &::after {
    transition: all 0.2s ease;
    content: "";
    width: 100%;
    height: 0;
    background: ${darken(0.2, Color.green)};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover {
    background: ${lighten(0.05, Color.green)};
    &::after {
      height: 8px;
    }
  }

  ${Media.below.tablet`
    background: transparent;
    color: ${Color.green};
    height: auto;
    padding: 0;
    padding: 1rem;

    &:hover, &::after {
      background: transparent;
    }
  `}
`

const NavA = styled.a`
  ${linkCss}
`

const NavLink = styled(Link)`
  ${linkCss}
`

export const Nav = ({ nav = [] }) => {
  const [on, setOn] = useState(false)
  return (
    <NavContainer>
      <Burg onClick={() => setOn(!on)} />
      <Wrapper>
        <NavFlex on={on}>
          <ExitButton onClick={() => setOn(!on)}>x</ExitButton>
          {nav.map((item, ix) => (
            <Fragment key={ix}>
              {item.external ? (
                <NavA href={item.url} target="_blank">
                  <span>{item.title}</span>
                </NavA>
              ) : (
                <NavLink to={item.url}>
                  <span>{item.title}</span>
                </NavLink>
              )}
            </Fragment>
          ))}
        </NavFlex>
      </Wrapper>
    </NavContainer>
  )
}
