import React, { useRef, useEffect } from "react"

export const RenderScript = ({ url }) => {
  const ref = useRef(null)
  useEffect(() => {
    let script = null
    if (ref) {
      script = document.createElement("script")

      console.log(script)
      console.log(ref)

      script.type = "text/javascript"
      script.src = url

      ref.current.appendChild(script)
    }
    return () => {
      ref.current.removeChild(script)
    }
  }, [ref])

  return <div className="" ref={ref}></div>
}
