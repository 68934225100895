import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

import { Color, Media, Type } from "../../utilities"

export const GlobalStyles = createGlobalStyle`
  ${normalize()};

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

  body, html {
    font-size: ${Type.basesize};
  }

  body {
    background-color: ${Color.bottom_footer};
    font-family: ${Type.text};
    color: ${Color.grey};
    line-height: 150%;
  }

  p {
      margin: 0;
      margin-bottom: 1em;
  }

  h1, h2, h3, h4, h5, h6, h7, h8, h9 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 100;
    line-height: 150%;
    margin-bottom: 1rem;
    color: ${Color.dark_green};
    font-weight: normal;
    text-transform: uppercase;
  }

  h1 {
      font-size: 3rem;
  }
  h2 {
      font-size: 2.5rem;
  }
  h3 {
      font-size: 2rem;
  }
  h4 {
      font-size: 1.5rem;
  }

  
  ${Media.below.tablet`
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.5rem;
    }
`}
`
