import React from "react"
import styled from "styled-components"

import { Media } from "../Media"

const StyledIframe = styled.iframe`
  position: left;
  z-index: 100;
  background: transparent;
  width: 540px;
  height: 80px;
  border: 0;

  ${Media.below.tablet`
    width: 100px;
    height: 140px;
    margin: auto;
    display: block;
  `}
`

export const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>
  }

  const src = source
  return (
    <StyledIframe
      src={src}
      id="tethered_frame"
      scrolling="no"
      title="Online Banking Login"
    ></StyledIframe>
  )
}
