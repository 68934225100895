import React from "react"
import styled from "styled-components"

import { Footer } from "../Footer"
import { Header } from "../Header"
import { GlobalStyles } from "../../elements"
import { Color, SEO } from "../../utilities"

const Body = styled.div`
  background: ${Color.white};
  line-height: 200%;
  font-size: 16px;
  color: ${Color.grey};

  p {
    font-size: 16px;
  }

  a {
    color: ${Color.green};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  small {
    font-size: 13px;
    line-height: 150%;
  }
`

export const Divider = styled.div`
  background-color: ${Color.green};
  height: 60px;
`

export const HeaderBorder = styled.div`
  height: 6px;
  width: 100%;
  margin: auto;
  margin-bottom: 1.5rem;
  border-radius: 20px;
  background-color: ${Color.green};
  background: linear-gradient(
    to left,
    #fff 0%,
    #2ba355 20%,
    #2ba355 80%,
    #fff 100%
  );

  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
  `}
`

export const fixContentLinks = content => {
  const regex = /^((http|https|ftp):\/\/)/
  if (regex.test(content)) {
    content = content.replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    )
  }

  return content
}

const nav = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Shared Branching",
    url: "https://www.sharedbranching.com",
    external: true,
  },
  {
    title: "CU @ Home Virtual Branch",
    url: "https://www.financial-net.com/sylvaniaareafcu",
    external: true,
  },
  {
    title: "Loan Applications",
    url: "/loan-application",
  },
  {
    title: "EZ Card Login",
    url: "https://www.ezcardinfo.com",
    external: true,
  },
  {
    title: "Order Checks",
    url: "https://reorder.libertysite.com",
    external: true,
  },
]

export const Layout = ({ children, title, description }) => (
  <>
    <GlobalStyles />
    <SEO title={title} description={description} />
    <Header nav={nav} />
    <Body>{children}</Body>
    <Footer />
  </>
)
