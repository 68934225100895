import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { lighten } from "polished"

import { Type, Color } from "../"

const BUTTON_MODIFERS_CONFIG = {
  primary: () => `
    background: ${Color.green};
    color: ${Color.white} !important;

    &:hover {
      background: ${lighten(0.2, Color.green)};
      text-decoration: none !important;
    }
    `,

  primaryOutline: () => `
    color: ${Color.green} !important;
    background: ${Color.white};
    border: 3px solid ${Color.green};

    &:hover {
      background: ${Color.green};
      text-decoration: none !important;
    }
  `,

  secondaryOutline: () => `
    color: ${Color.grey} !important;
    background: ${Color.white};
    border: 3px solid ${Color.grey};

    &:hover {
      background: ${Color.grey};
      text-decoration: none !important;
    }
  `,

  large: () => `
    padding: 1.5rem 4rem;
    font-size: 2rem;
  `,

  small: () => `
    padding: 0.8rem 1rem
  `,

  block: () => `
    margin: 0 !important;
    width: 100%;
    text-align: center;
  `,
}

const buttonStyles = css`
  font-size: 1rem;
  border: 0;
  font-family: ${Type.text};
  font-weight: 100 !important;
  text-align: center;
  text-decoration: none !important;
  box-sizing: border-box;
  transition: all 0.4s ease;
  padding: 1rem 2rem;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none !important;
    color: ${Color.white} !important;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  ${applyStyleModifiers(BUTTON_MODIFERS_CONFIG)};
`

export const Button = styled.button`
  ${buttonStyles};
`

export const ButtonA = styled.a`
  display: block;
  ${buttonStyles};
`

export const ButtonLink = styled(Link)`
  ${buttonStyles};
`
