import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Color, Col, Media, Type, Grid, AlignCenter } from "../../utilities"
import { Section } from "../../elements"

export const convertUrl = url =>
  url.replace(`https://${process.env.GATSBY_API_URL}`, "")

const FriendsTitle = styled.div`
  background-color: ${Color.green};
  padding 2rem;
  color: ${Color.white};
  font-family: ${Type.text};
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  ${Media.below.tablet`
    line-height: 100%;
  `}
`

const FriendGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-content: center;
  align-items: center;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}

  ${Col} {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
`

const BottomFooter = styled.div`
  background-size: cover;
  padding: 2rem 0;
  text-align: center;
  font-size: 0.9rem;

  a {
    font-size: 0.9rem;
  }
`

const FooterSection = styled(Section)`
  padding-top: 4rem;
  padding-bottom: 6rem;
  text-align: center;
`

const FooterContainer = styled.div`
  line-height: 200%;
  color: ${Color.footer_text};

  a {
    text-decoration: none;
    color: ${Color.white};

    &:hover {
      text-decoration: underline;
    }
  }
`

const NoticeText = styled.div`
  padding-top: 2rem;
  font-size: 0.9rem;
`

const FooterLeftFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 1.5rem;
    display: block;
  }
`

const FooterTopGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: auto;
  max-width: 1000px;

  ${Media.below.tablet`
    display: block;
  `}
`

const getYear = () => {
  const d = new Date()
  return d.getFullYear()
}

export const Footer = () => {
  const { equal, star, sacoc, ncua, bbb } = useStaticQuery(graphql`
    query FooterQuery {
      equal: file(relativePath: { eq: "equal-housing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            src
          }
        }
      }
      star: file(relativePath: { eq: "five-star.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            src
          }
        }
      }
      sacoc: file(relativePath: { eq: "sacoc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            src
          }
        }
      }
      ncua: file(relativePath: { eq: "ncua.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            src
          }
        }
      }
      bbb: file(relativePath: { eq: "bbb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            src
          }
        }
      }
    }
  `)

  return (
    <FooterContainer>
      <FriendsTitle>Friends of the Community</FriendsTitle>
      <Section>
        <FriendGrid>
          <Col>
            <img src={star.childImageSharp.fluid.src} />
          </Col>
          <Col>
            <img src={sacoc.childImageSharp.fluid.src} />
          </Col>
          <Col>
            <img src={ncua.childImageSharp.fluid.src} />
          </Col>
          <Col>
            <a
              href="https://www.bbb.org/us/oh/sylvania/profile/credit-union/sylvania-area-federal-credit-union-0422-9004627"
              target="_blank"
            >
              <img src={bbb.childImageSharp.fluid.src} />
            </a>
          </Col>
        </FriendGrid>
      </Section>
      <FooterSection background={Color.footer}>
        <FooterTopGrid>
          <Col>
            <FooterLeftFlex>
              <Col>
                <span>STAGING</span>
                <Link to="/disclosures">Disclosures</Link>
              </Col>
              <Col>
                <img src={equal.childImageSharp.fluid.src} />
              </Col>
            </FooterLeftFlex>
          </Col>
          <Col>
            Shared Branch ATM only, Alliance One Member, Open 24/7 at the credit
            union.
            <br />
            Questions regarding disclosures? Please call us at{" "}
            <a href="tel: 4198823525">419 882-3525</a>
            <br />
            Question regarding lost or stolen cards? ATM/Debit Cards call:{" "}
            <a href="tel: 18889187882">1-888-918-7882</a>
            <br />
            Visa Cards call: <a href="tel: 18003228472">1-800-322-8472</a>
          </Col>
        </FooterTopGrid>
        <NoticeText>
          Sylvania Area Federal Credit Union is committed to providing a website
          that is accessible to the widest possible audience with ADA standards
          and guidelines. We are actively working to increase accessability and
          useability of our website to everyone. If you are using a screen
          reader or other auxiliary aid and are having problems using this
          website, please contact us at <br />
          419-882-3525. All products and services available on this website are
          available at all Sylvania Area Federal Credit Union Branches.
        </NoticeText>
      </FooterSection>
      <BottomFooter>
        &copy;{getYear()} Website Design by{" "}
        <a href="https://toddprod.com" target="_blank">
          Todd Productions inc.
        </a>
      </BottomFooter>
    </FooterContainer>
  )
}
